@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300;400;500;700;800&display=swap');


/* ------------ Global targets -------------- */
* {
    margin: 0;
    padding: 0;
    font-family: 'M PLUS 1p', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: content-box; 
    text-rendering: optimizeLegibility;
}

body {
  background-color: #F1EEEB;
}

a {
  text-decoration: none;
  color: white;
}

ul {
  list-style-type: none;
}

.logo {
  height: 100px;
  width: 130px;
  color: #f1eeeb00;
}

.logo a {
  display: none;
  background-color: #f1eeeb00;
}


  /* -------- COOKIE BANNER ------- */

.cookie-banner{
  position: fixed;
  z-index: 100;
  bottom: 0; 
  width: 100%;
  height: 25%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;  
  border-radius: 10px;
}
  
.cookie-banner-text {
  font-family: 'M PLUS 1p', sans-serif;
  font-weight: 300;
  font-size: 12px;
  margin: 0;
}

.cookie-info {
  color: black;
  font-weight: 400;
}

#accept-cookies-btn {
  width: 180px;
  padding: 10px;
  cursor: pointer;
  border: none;
  font-size: 12px;
  color: black;
  background-color: #C5BFB8;
  text-transform: uppercase;
  font-weight: 400;
}

#accept-cookies-btn:hover {
  background-color: black;
  color: white;
  transition: 0.7s ease;
}

  /* -------- INSTAGRAM EMBED save for later ------- */

/* .elfsight-app-55ce35a0-0d2a-41cf-a6be-b1ff2d427fcf {
  display: none;

}

@media (min-width: 668px) {

  .elfsight-app-55ce35a0-0d2a-41cf-a6be-b1ff2d427fcf {
    display: flex;
    margin-top: 50px;
    margin-left: 50px;
    width: 100vw;
  }
} */

/* ------------ Navbar ------------- */
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .flex-parent {
    padding: 1rem;
    display: flex;
    gap: 1rem;
  }

  .flex-child {
    display: inline-flex;
    align-items: center;
    padding: 6px 3px;
    margin-right: 4px;
  }

  /* ----------- home-page ------------ */
  
  .home-container {
    display: flex;
    justify-content: center;
  }
  
  .hello {
    font-size: 35px;
    font-weight: 300;
    font-style: italic;
    margin-top: 270px;
  }
  
  .product {
    color: black;
    font-size: 25px;
  }

  /* ------------RENTAL page -------------*/

  .product-container {
    margin: auto;
  }

  .product-span {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: white; */
    height: 220px;
  }

  .title-span {
    display:block;
    position:relative;
    display: flex; 
    justify-content: center;
    justify-items: end;
    padding: 4px;
    /* background-color: white; */
  }

  .product-image {
      object-fit: cover;
      width: 150px;
      height: 150px;
      background-color: white;
    }


  .product-title {
    font-size: 20px;
    text-align: center;
    margin: 5px;
    background-color: white;

  } 

  .qty-button {
    width: 50px;
    margin: 5px;
  }

/* BOOKING FORM STYLING */

.get-in-touch-1 {
  font-size: 25px;
  margin: 0;
  padding: 4em 0 .5em 0;
}
.get-in-touch-2 {
  padding-bottom: 3em;
  font-size: 17px;
  font-weight: 300;
  margin: 0;
}

.custom-field {
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-transform: uppercase;
  font-weight: 300;

}

.custom-field-checkbox {
  font-size: 15px;
  font-weight: 300;
  padding-left: 5px;
}

.custom-field input {
  border: none;
  background: white;
  padding: 10px;
  border-radius: 3px;
  width: 15rem;
  font-size: 14px;
  margin: 15px 15px 15px 0;
}

textarea {
  border: none;
  background: white;
  padding: 10px;
  border-radius: 3px;
  width: 15rem;
  font-size: 14px;
  resize: none;
  margin: 15px 15px 15px 0;
}


.loader {
  border-top: 16px solid white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 35px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.swal-button {
  width: 8rem;
  margin: 15px 0 65px 0;
  cursor: pointer;
  border: none;
  font-size: 16px;

  padding: 0.8rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  font-weight: 450;
}

/* 
.swal-button:hover {
  color: black;
  background-color: #B3635A;
  transition: 0.7s;
} */

.swal-title {
  margin: 0px;
  font-size: 16px;
  margin-bottom: 28px;
  background-color: white;
}

.swal-text {
  background-color: white;
  padding: 17px;
  display: block;
  margin: 22px;
  text-align: center;
  color: black;
} 

.accordion {
  max-width: 255px;
}
.accordion-item {
  list-style: none;
}
.accordion-toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ccc;
  padding: 0.7em;
  margin-top: 10px;
}
.accordion-toggle:hover {
  background-color: #ddd;
}
.accordion-toggle h3 {
  margin: 0;
       font-size: 16px;
       font-weight: 305;
  line-height: 1.8em;
}
.accordion-content {
  /* background-color: #eee;
  padding: 1em; */
  /* display: flex;
       justify-content: center; */
       font-size: 16px;
       font-weight: 305;
       margin: 0.5rem;
  line-height: 1.8em;
}

@media (min-width: 768px) {

  textarea {
    width: 20rem;
  }
  .custom-field input {
    width: 20rem;

  }


  @media (min-width: 950px) {

      /* -------- COOKIE BANNER ------- */

    .cookie-banner{
      position: fixed;
      bottom: 5%; 
      left: 25%;
      width: 50%;
      height: 25%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;  
      border-radius: 10px;
    }
    
    .cookie-banner-text {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 300;
      font-size: 16px;
    }

    .cookie-info {
      color: black;
      font-weight: 400;
    }

    #accept-cookies-btn {
      width: 180px;
      padding: 10px;
      cursor: pointer;
      border: none;
      font-size: 14px;
      color: black;
      background-color: #C5BFB8;
      text-transform: uppercase;
      font-weight: 400;
    }

    #accept-cookies-btn:hover {
      background-color: black;
      color: white;
      transition: 0.7s ease;
    }
    
  }
    
}